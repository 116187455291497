import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Title from '../components/Title/title';
import styles from './post.module.scss';

export default ({ data }) => {
    const post = data.markdownRemark;

    return (
        <Layout>
            <main className={styles.container}>
                <Title title={post.frontmatter.title} subtitle={post.frontmatter.subtitle}/>
                <article className={styles.content} dangerouslySetInnerHTML={{ __html: post.html}}></article>
                <div className={styles.changeCaseStudy}>
                    <Link className={styles.link} to={post.frontmatter.previousPage}>Previous</Link>
                    <Link className={styles.link} to={post.frontmatter.nextPage}>Next</Link>
                </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                subtitle
                nextPage
                previousPage
            }
        }
    }
`